import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, filter, finalize } from "rxjs";
import { LoadingIndicatorService } from "./loading-indicator.service";

export const loadingInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const loadingIndicatorService = inject(LoadingIndicatorService);

    loadingIndicatorService.addRoute(request.url);

    return next(request).pipe(
        filter((event) => event.type === HttpEventType.Response),
        finalize(() => loadingIndicatorService.removeRoute(request.url))
    );
};
