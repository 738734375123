import { HttpClient } from "@angular/common/http";
import { inject, Injectable, Signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { filterNonEmptyProperties } from "@kno2/common/utils";
import { firstValueFrom, Observable, switchMap } from "rxjs";
import { User, UserMessageEvent } from "./user.model";

export interface UserEventsQuery {
    pageSize: number;
    pageStart: number;
    prop?: "status" | "date" | "to" | "from";
    search?: string;
}

@Injectable({
    providedIn: "root"
})
export class UsersService {
    private readonly httpClient = inject(HttpClient);

    public getUser = (userId: string): Promise<User> => firstValueFrom(this.getUser$(userId));
    public getClientIp = (): Promise<string> => firstValueFrom(this.getClientIp$());
    public updateUser = (user: User): Promise<User> => firstValueFrom(this.updateUser$(user));
    public removeApplication = (userId: string, appId: string): Promise<void> => firstValueFrom(this.removeApplication$(userId, appId));
    public getEvents = (userId: string, query: Signal<UserEventsQuery>) =>
        toSignal(this.getEvents$(userId, query), { initialValue: { items: [], totalCount: 0 } });

    private getUser$(userId: string): Observable<User> {
        return this.httpClient.get<User>(`/api/users/${userId}`);
    }

    private getClientIp$(): Observable<string> {
        return this.httpClient.get<string>("/api/users/ipaddress");
    }

    private updateUser$(user: User): Observable<User> {
        return this.httpClient.put<User>(`/api/users/${user.id}`, user);
    }

    private removeApplication$(userId: string, appId: string): Observable<void> {
        return this.httpClient.delete<void>(`/api/users/${userId}/applications/${appId}`);
    }

    private getEvents$(userId: string, query: Signal<UserEventsQuery>): Observable<{ items: UserMessageEvent[]; totalCount: number }> {
        return toObservable(query).pipe(
            switchMap((params) =>
                this.httpClient.get<{ items: UserMessageEvent[]; totalCount: number }>(`/api/users/${userId}/events`, {
                    params: {
                        pageSize: 30,
                        pageStart: 1,
                        ...filterNonEmptyProperties(params)
                    }
                })
            )
        );
    }
}
