import { Component, input, output } from "@angular/core";
import { PaginatorModule, PaginatorState } from "primeng/paginator";

@Component({
    selector: "kno2-pagination",
    standalone: true,
    imports: [PaginatorModule],
    templateUrl: "./pagination.component.html",
    styles: `
        ::ng-deep .p-paginator  {
            padding-right: 0;
        }

        ::ng-deep .p-paginator .p-paginator-page.p-highlight {
            color: #fff;
            background-color: #428bca;
            border-color: #428bca;
        }

        ::ng-deep .p-paginator .p-element {
            font-size: 14px !important;
            font-family: "Lato", sans-serif;
            height: 2.5em;
            padding: 6px 12px;
            min-width: 2rem;
            border: 1px solid #dddddd;
        }

        ::ng-deep .p-paginator .p-paginator-page-input {
            padding: 0;
            margin-right: 0;
            border: none;

            input {
                border-radius: 0;
            }
        }

        `
})
export class PaginationComponent {
    public collectionSize = input.required<number>();
    public page = input.required<number>();
    public pageSize = input.required<number>();
    public showPageInput = input<boolean>(false);
    public debounceTime = input<number>(300);

    protected pageChange = output<number>();

    private timeout: ReturnType<typeof setTimeout>;

    protected onPaginationChange(event: PaginatorState): void {
        clearTimeout(this.timeout);

        const targetPage = Math.ceil((event.first + 1) / event.rows);
        this.timeout = setTimeout(() => this.pageChange.emit(targetPage), this.debounceTime());
    }
}
