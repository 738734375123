@let headerTooltip = "A Release (or Release of Information) is the process used to release patient information from your organization to a recipient, such as for a referral. <br /><br />To initiate a new release, click the Initiate Release button. <br /><br /> Please note:  Only one patient can be assigned to a release.`";
@let recentActivityTooltip = "Released includes all of the most recent release activity and the status of each release.";
@let draftsTooltip = "Drafts includes all of your organization's message drafts and allows you to edit and complete them.";

<div class="content-full">
    <div class="row">
        <div class="col-sm-12 auto-fill">
            <div class="col-sm-12 col-no-gutters">
                <div class="box-content">
                    <div class="main">
                        <div class="row intake-header">
                            <div class="col-sm-12">
                                <div class="intake-header-title pull-left">
                                    <h2>
                                        <span class="break"></span>
                                        Release
                                        <kno2-info-popover [content]="headerTooltip" />
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div
                    class="col-sm-12 release-grid"
                    style="margin-top: -15px">
                    <div class="container row flex-column align-items-end">
                        <div class="btn-group release-dropdown">
                            <button
                                class="btn btn-info"
                                type="button"
                                popoverPlacement="bottom"
                                routerLink="/release/new"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="New Release">
                                &nbsp; New Release &nbsp;
                                <i class="fa fa-upload"></i>
                            </button>
                            <button
                                k2-feature-toggle="FaxDocumentSource"
                                class="btn btn-info dropdown-toggle"
                                id="moreActionsDropdown"
                                data-toggle="dropdown"
                                data-display="static"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <span class="caret"></span>
                            </button>
                            <div
                                class="dropdown-menu dropdown-menu-right actions-dropdown"
                                data-display="static"
                                aria-labelledby="moreActionsDropdown">
                                <a
                                    class="dropdown-item"
                                    (click)="showNonPhiFaxModal()">
                                    <i class="fa fa-list-alt fa-fw"></i>
                                    &nbsp;Send Non-PHI Fax
                                </a>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-tabs">
                        <li
                            class="nav-item"
                            (click)="changeActiveTab('events')"
                            [class.active]="activeTab === 'events'">
                            <a
                                kno2NoOpLink
                                class="nav-link">
                                <div class="grid-icon"><i class="fa fa-upload"></i></div>
                                Released
                                <kno2-info-popover
                                    class="ml-2"
                                    [content]="recentActivityTooltip" />
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            (click)="changeActiveTab('drafts')"
                            [class.active]="activeTab === 'drafts'">
                            <a
                                kno2NoOpLink
                                class="nav-link">
                                <div class="grid-icon"><i class="fa fa-file"></i></div>
                                Drafts
                                <kno2-info-popover
                                    class="ml-2"
                                    [content]="draftsTooltip" />
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        @if (activeTab === "events") {
                            <kno2-release-history />
                        } @else if (activeTab === "drafts") {
                            <kno2-release-drafts />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
