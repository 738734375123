import { Component, input } from "@angular/core";
import { PopoverDirective } from "@kno2/common/directives";

@Component({
    selector: "kno2-info-popover",
    standalone: true,
    imports: [PopoverDirective],
    templateUrl: "./info-popover.component.html"
})
export class InfoPopoverComponent {
    public content = input.required<string>();
}
