import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "fax",
    standalone: true
})
export class FaxPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): string {
        const regEx = /^\d+$/;

        if (!regEx.test(value)) return value;

        if (value.length === 10) return value.replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3");
        if (value.length === 11) return value.replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");
        if (value.length === 12) return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");
        if (value.length === 13) return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");

        return value;
    }
}
