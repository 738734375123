<kno2-table
    [columns]="columns"
    [data]="messages().items"
    [rowActions]="rowActions">
    <ng-template
        #subject
        let-draft>
        <a
            class="kno2-link"
            [routerLink]="['/release/draft', draft.id]">
            {{ draft.subject }}
        </a>
    </ng-template>
    <ng-template
        #rowActions
        let-draft>
        <button
            (click)="editDraft(draft.id)"
            class="btn"
            alt="Edit"
            data-toggle="tooltip"
            title="Edit">
            <i class="fa fa-pencil"></i>
        </button>
        <button
            (click)="deleteDraft(draft.id)"
            class="btn ml-1"
            alt="Delete"
            data-toggle="tooltip"
            title="Delete">
            <i class="fa fa-trash"></i>
        </button>
    </ng-template>
</kno2-table>

<kno2-pagination
    [collectionSize]="messages().totalCount"
    [page]="pageOptions().pageStart"
    [showPageInput]="true"
    [pageSize]="pageOptions()?.pageSize"
    (pageChange)="updateMessagesQuery({ pageStart: $event })" />
