import { DOCUMENT } from "@angular/common";
import { inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FileSaveService {
    private readonly document = inject(DOCUMENT);

    public saveFile(fileContent: ArrayBuffer, contentType: string, fileName: string): void {
        const blob = new Blob([fileContent], { type: contentType });
        const url = this.document.defaultView.URL.createObjectURL(blob);
        const a = this.document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        this.document.defaultView.URL.revokeObjectURL(url);
    }
}
