import { AfterViewInit, Component, computed, inject, Input, signal } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SplitOnPipe } from "@kno2/common/pipes";
import { AuditQueryParans, AuditService } from "@kno2/data-access/audit";
import { FormDateInputComponent } from "@kno2/shared/forms";
import { PaginationComponent } from "@kno2/shared/pagination";
import { TableColumn, TableComponent } from "@kno2/shared/table";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";
import { InfoPopoverComponent } from "../../shared/info-popover/info-popover.component";

@Component({
    selector: "kno2-message-activity-modal",
    standalone: true,
    imports: [TableComponent, PaginationComponent, FormDateInputComponent, InfoPopoverComponent],
    providers: [SplitOnPipe],
    templateUrl: "./message-activity-modal.component.html"
})
export class MessageActivityModalComponent implements AfterViewInit {
    private readonly activeModal = inject(NgbActiveModal);
    private readonly auditService = inject(AuditService);
    private readonly splitOnPipe = inject(SplitOnPipe);

    protected filterKey = "all";
    protected filters = [
        { key: "all", display: "All", events: null },
        { key: "viewed", display: "Viewed", events: ["IntakeMessageView"] },
        {
            key: "processed",
            display: "Processed",
            events: [
                "IntakeMessageProcessed",
                "IntakeMessageExported",
                "IntakeMessagePrinted",
                "IntakeMessageForwardedToEMR",
                "IntakeMessageAwaitingEMRExport",
                "IntakeMessageEMRExported"
            ]
        },
        { key: "replied", display: "Replied", events: ["ReleaseMessageReplied"] },
        { key: "forwarded", display: "Forwarded", events: ["ReleaseMessageForwarded"] },
        { key: "deleted", display: "Deleted", events: ["IntakeMessageDeleted"] }
    ];

    protected dateRangeForm = new FormGroup({
        startDate: new FormControl(),
        endDate: new FormControl()
    });
    protected columns = signal<TableColumn[]>([]);
    protected correlationId = signal<string>(null);
    protected query = signal<AuditQueryParans>({
        eventTypes: this.filters.find((filter) => filter.key === this.filterKey)?.events
    });
    protected pageOptions = { page: 1, pageSize: 10 };
    protected auditEntries = this.auditService.getAuditEntriesByTypes(this.correlationId, this.query);
    protected pagedAuditEntires = computed(() => {
        return this.auditEntries()?.reduce((acc, entry) => {
            if (acc.length === 0 || acc[acc.length - 1].length === 10) {
                acc.push([]);
            }

            acc[acc.length - 1].push(entry);
            return acc;
        }, []);
    });

    @Input() public messageId?: string;
    @Input() public messageSubject: string;

    public ngAfterViewInit(): void {
        this.columns.set(this.getColumns());
        this.correlationId.set(this.messageId);
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public updateQuery(filterKey: string): void {
        this.filterKey = filterKey;
        this.query.update((query) => ({
            ...query,
            eventTypes: this.filters.find((filter) => filter.key === filterKey)?.events,
            startDate: DateTime.fromObject(this.dateRangeForm.value.startDate).startOf("day").toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            endDate: DateTime.fromObject(this.dateRangeForm.value.endDate).endOf("day").toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
        }));
    }

    private getColumns(): TableColumn[] {
        return [
            { field: "createdBy", headerText: "User", cellClass: "email-address small text-truncate" },
            { field: "eventType", headerText: "Type", cellClass: "small", formatter: (value: string) => this.splitOnPipe.transform(value, "uppercase") },
            {
                field: "createdOn",
                headerText: "Date",
                cellClass: "small",
                formatter: (value: string) => DateTime.fromISO(value, { zone: "utc" }).setZone("local").toFormat("h:mm a MM/dd/yyyy")
            }
        ];
    }
}
