<div class="d-flex">
    <div class="dropdown">
        <button
            style="border: none; background: none; color: #a5b1b6"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i class="fa fa-caret-square-o-down"></i>
        </button>
        <ul
            class="dropdown-menu"
            role="menu"
            aria-labelledby="dropdownMenuButton">
            <li>
                <a
                    routerLink="/release/forward/{{ event().id }}"
                    [queryParams]="{ releaseType: 'FW: ' + event().subject.replace('FW: ', '') }">
                    <i class="fa fa-mail-forward"></i>
                    &nbsp; Forward
                </a>
            </li>
            @if (event().isReadyForDeliverySummary && deliverySummaryEnableDownload()) {
                <li>
                    <a
                        kno2NoOpLink
                        (click)="downloadDeliverySummary(event().id)">
                        <i class="fa fa-download fa-fw"></i>
                        &nbsp;Download Delivery Summary
                    </a>
                </li>
            }
            @if (event().isReadyForDeliverySummary) {
                <li>
                    <a
                        kno2NoOpLink
                        (click)="openSendDeliverySummaryToIntakeModal(event().id)">
                        <i class="fa fa-download fa-fw"></i>
                        &nbsp;Send Delivery Summary To Intake
                    </a>
                </li>
            }
            <li>
                <a
                    kno2NoOpLink
                    (click)="deleteEvent(event())">
                    <i class="fa fa-trash fa-fw"></i>
                    &nbsp; Delete
                </a>
            </li>
            <li k2-toggle-role="Administrator">
                <a
                    kno2NoOpLink
                    (click)="showActivity(event().id, event().subject)">
                    <i class="fa fa-list-alt fa-fw"></i>
                    &nbsp; Activity
                </a>
            </li>
        </ul>
    </div>
    <span
        [kno2Popover]="popover"
        popoverTitle="All Activities"
        [popoverContext]="event()"
        popoverPlacement="bottom"
        class="dashboard-status"
        [class]="event().status | lowercase">
        {{ event().status }}
    </span>
</div>

<ng-template
    #popover
    let-event>
    <div>
        <div style="margin-left: 10px">
            <small>
                <strong>Source:</strong>
                {{ event.origin }}
            </small>
        </div>
        <div>
            <table class="table">
                <tbody>
                    @for (subEvent of event.events; track $index) {
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-sm-5">
                                        <div
                                            class="dashboard-status"
                                            [class]="subEvent.eventType | lowercase">
                                            {{ subEvent.eventType }}
                                        </div>
                                    </div>
                                    <div class="col-sm-7 text-right">
                                        {{ subEvent.createdDate + "Z" | date: "MM/dd/YY hh:mm a" }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
