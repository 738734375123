<div
    (click)="toggleCollapse.emit()"
    class="inline-box-header rules-header">
    <div class="inline-box-title rules-header-title">
        <h2>Downloads</h2>
    </div>
    <span
        class="pull-left"
        [kno2Popover]="tooltip">
        <i class="fa fa-info-circle box-info"></i>
    </span>
    <div class="header-ctrls">
        <div class="header-ctrl box-icon">
            <a>
                <i
                    class="fa"
                    [class.fa-chevron-down]="!expanded"
                    [class.fa-chevron-up]="expanded"></i>
            </a>
        </div>
    </div>
</div>
<div
    class="inline-box-content"
    [class.in]="expanded"
    [class.collapse]="!expanded">
    @for (download of downloads(); track $index) {
        <div class="row table borderless support-center-download-row">
            <div class="col-sm-1 lnk cursor-default support-center-download-icon"></div>
            <div class="col-sm-11 pad0 support-center-link">
                <a
                    href="{{ baseUrl }}/api/downloads/{{ download.id }}/download"
                    [download]="download.name">
                    {{ download.displayName }} (Version {{ download.platform.version }})
                </a>
            </div>
        </div>
    }
</div>
