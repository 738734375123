import { Directive, ElementRef, inject, input, TemplateRef, ViewContainerRef } from "@angular/core";
import { Boundary } from "@popperjs/core";
import { Tooltip } from "bootstrap";

declare const $: JQueryStatic;
@Directive({
    selector: "[kno2Popover]",
    standalone: true
})
export class PopoverDirective {
    private viewContainerRef = inject(ViewContainerRef);
    private el = inject(ElementRef);

    protected kno2Popover = input<TemplateRef<unknown> | string>();
    protected popoverTitle = input<string>("Tip");
    protected popoverContext = input<unknown>();
    protected popoverPlacement = input<string>("auto");

    ngAfterViewInit() {
        const element = $(this.el.nativeElement);
        if (!element.data("bs.popover")) {
            element.popover({
                html: true,
                placement: this.popoverPlacement() as Tooltip.PopoverPlacement,
                container: "body",
                boundary: "window" as Boundary,
                trigger: "hover",
                title: this.popoverTitle(),
                content: () => {
                    const popoverContent = this.kno2Popover();
                    if (typeof popoverContent === "string") return popoverContent;

                    this.viewContainerRef.clear();
                    const view = this.viewContainerRef.createEmbeddedView(popoverContent as TemplateRef<unknown>, { $implicit: this.popoverContext() });
                    view.detectChanges();
                    return view.rootNodes[0];
                },
                delay: {
                    show: 200,
                    hide: 200
                }
            });
        }
    }

    ngOnDestroy() {
        const element = $(this.el.nativeElement);
        if (element.data("bs.popover")) {
            element.popover("dispose");
        }
    }
}
