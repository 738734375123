import { Component, input, output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "kno2-pagination-page-size",
    standalone: true,
    imports: [FormsModule],
    template: `
        <div
            id="DataTables_Table_0_length"
            class="dataTables_length">
            <label>
                <select
                    id="pagedItemsSelect"
                    class="form-control-auto"
                    [(ngModel)]="pageSize"
                    (change)="pageSizeChange.emit({ pageSize })">
                    @for (option of options(); track $index) {
                        <option [value]="option">{{ option }}</option>
                    }
                </select>
                &nbsp;&nbsp;records per page
            </label>
        </div>
    `
})
export class PaginationPageSizeComponent {
    public options = input<number[]>([10, 25, 50, 100]);
    public pageSizeChange = output<{ pageSize: number }>();
    protected pageSize = this.options()[0];
}
