import { AfterViewInit, Component, inject, OnDestroy, OnInit, signal, TemplateRef, viewChild } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { SessionService } from "@kno2/core/session";
import { UserEventsQuery, UserMessageEvent, UsersService } from "@kno2/data-access/users";
import { PaginationComponent } from "@kno2/shared/pagination";
import { TableColumn, TableComponent } from "@kno2/shared/table";
import { DateTime } from "luxon";
import { ReleaseStatusCellContentComponent } from "./release-status-cell-content/release-status-cell-content.component";

@Component({
    selector: "kno2-release-history",
    standalone: true,
    imports: [PaginationComponent, TableComponent, RouterLink, ReleaseStatusCellContentComponent],
    templateUrl: "./release-history.component.html",
    styles: ``
})
export class ReleaseHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly sessionService = inject(SessionService);
    private readonly usersService = inject(UsersService);

    private readonly upgrade = inject(UpgradeModule);
    private readonly interoperabilityService = this.upgrade.$injector.get("InteroperabilityService");

    private refreshEventsInterval: ReturnType<typeof setInterval>;

    protected statusTemplate = viewChild<TemplateRef<{ event: UserMessageEvent }>>("status");
    protected subjectTemplate = viewChild<TemplateRef<{ event: UserMessageEvent }>>("subject");

    protected deliverySummaryEnableDownload = false;
    protected pageOptions = signal<UserEventsQuery>({
        pageStart: 1,
        pageSize: 10
    });
    protected events = this.usersService.getEvents(this.sessionService.getProfile().userId, this.pageOptions);
    protected columns = signal<TableColumn[]>([]);

    public ngOnInit(): void {
        this.setRefreshEventsInterval();
        this.interoperabilityService.get().then(({ data }) => {
            this.deliverySummaryEnableDownload = data.deliverySummaryEnableDownload;
        });
    }

    public ngAfterViewInit(): void {
        this.columns.set(this.getColumns());
    }

    public ngOnDestroy(): void {
        clearInterval(this.refreshEventsInterval);
    }

    public updateEventsQuery(query?: UserEventsQuery): void {
        this.pageOptions.update((options) => ({
            ...options,
            ...(query || {})
        }));
        this.setRefreshEventsInterval();
    }

    public filterEvents(query: UserEventsQuery): void {
        this.pageOptions.update((options) => {
            const { pageStart, pageSize, ...opts } = options;
            const { prop, search } = query || {};
            return {
                pageStart,
                pageSize,
                prop,
                search
            };
        });
        this.setRefreshEventsInterval();
    }

    private getColumns(): TableColumn[] {
        return [
            {
                field: "status",
                templateRef: this.statusTemplate(),
                cellClass: "overflow-visible pb-2",
                filter: {
                    type: "select",
                    exact: true,
                    values: [
                        "Bounced",
                        "Deleted",
                        "Delivered",
                        "Exported",
                        "Failed",
                        "Faxed",
                        "Forwarded",
                        "Forwarding",
                        "Pending",
                        "Processed",
                        "Read",
                        "Received",
                        "Replied",
                        "Replying",
                        "Sending",
                        "Sent",
                        "Unknown"
                    ]
                }
            },
            {
                field: "messageSentDate",
                headerText: "Sent",
                formatter: (value: string) => DateTime.fromISO(value, { zone: "utc" }).setZone("local").toFormat("h:mm a MMM d, y"),
                filter: {
                    type: "date",
                    prop: "date",
                    exact: true
                }
            },
            {
                field: "subject",
                templateRef: this.subjectTemplate()
            },
            {
                field: "to",
                filter: {
                    type: "text"
                }
            },
            {
                field: "from",
                filter: {
                    type: "text"
                }
            }
        ];
    }

    private setRefreshEventsInterval(): void {
        clearInterval(this.refreshEventsInterval);
        this.refreshEventsInterval = setInterval(() => {
            this.updateEventsQuery();
        }, 15000);
    }
}
