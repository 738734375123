import { HttpClient } from "@angular/common/http";
import { inject, Injectable, Signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { filterNonEmptyProperties } from "@kno2/common/utils";
import { DateTime } from "luxon";
import { combineLatest, filter, Observable, switchMap } from "rxjs";

export interface AuditEntry {
    id: string;
    subDomain: string;
    entityType: string;
    correlationId: string;
    eventType: string;
    eventData: string;
    patientId: string;
    patientName: string;
    ipAddress: string;
    requestType: string;
    createdById: string;
    createdBy: string;
    createdOn: string;
}

export interface AuditQueryParans {
    eventTypes?: string[];
    startDate?: string;
    endDate?: string;
}

@Injectable({
    providedIn: "root"
})
export class AuditService {
    private readonly httpClient = inject(HttpClient);

    public getAuditEntriesByTypes = (correlationId: Signal<string>, query: Signal<AuditQueryParans>): Signal<AuditEntry[]> =>
        toSignal(this.getAuditEntriesByTypes$(correlationId, query));

    private getAuditEntriesByTypes$(correlationId: Signal<string>, query: Signal<AuditQueryParans>): Observable<AuditEntry[]> {
        return combineLatest([toObservable(correlationId), toObservable(query)]).pipe(
            filter(([correlationId]) => !!correlationId),
            switchMap(([correlationId, params]) => {
                return this.httpClient.get<AuditEntry[]>(`/api/audit/${correlationId}/events`, {
                    params: filterNonEmptyProperties({
                        ...params,
                        eventTypes: params.eventTypes?.join(","),
                        startDate: params.startDate || DateTime.now().minus({ days: 14 }).startOf("day").toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
                        endDate: params.endDate || DateTime.now().endOf("day").toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
                    })
                });
            })
        );
    }
}
