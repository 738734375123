<p-table
    [value]="data()"
    tableStyleClass="table table-striped table-bordered bootstrap-datatable datatable actions-table">
    <ng-template pTemplate="header">
        <tr>
            @for (column of columns(); track column.field) {
                <th class="overflow-visible">
                    <div class="d-flex justify-content-between">
                        <span>
                            {{ column.headerText || (column.field | splitOn: "uppercase" | titlecase) }}
                            @if (column.canSort) {
                                <a (click)="sort(column.field, column)">
                                    <i [class]="column.sortDir === 'desc' ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
                                </a>
                            }
                        </span>

                        @if (column.filter; as columnFilter) {
                            <kno2-column-filter
                                [isActive]="column.field === activeFilter"
                                [options]="columnFilter"
                                (onFilter)="filter(column.field, $event)" />
                        }
                        @if (column.field === "status") {}
                    </div>
                </th>
            }
            @if (rowActions()) {
                <th class="actions-cell-header"></th>
            }
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-data>
        <tr>
            @for (column of columns(); track column.field) {
                <td
                    class="align-middle"
                    [class]="column.cellClass">
                    @if (column.templateRef; as templateRef) {
                        <ng-container
                            [ngTemplateOutlet]="templateRef"
                            [ngTemplateOutletContext]="{ $implicit: data }"></ng-container>
                    } @else if (!!column.formatter) {
                        {{ column.formatter(data[column.field]) }}
                    } @else {
                        {{ data[column.field] }}
                    }
                </td>
            }

            @if (rowActions(); as actions) {
                <td class="actions-cell-group">
                    <ng-container
                        [ngTemplateOutlet]="actions"
                        [ngTemplateOutletContext]="{ $implicit: data }"></ng-container>
                </td>
            }
        </tr>
    </ng-template>
</p-table>
