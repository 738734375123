import { DatePipe, LowerCasePipe } from "@angular/common";
import { Component, inject, input, output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NoOpLink, PopoverDirective } from "@kno2/common/directives";
import { FileSaveService, HttpUtilitiesService } from "@kno2/common/services";
import { NotificationService } from "@kno2/common/services/notifications";
import { MessagesService } from "@kno2/data-access/messages";
import { UserMessageEvent } from "@kno2/data-access/users";
import { MessageActivityModalComponent } from "@kno2/features/message-activity-modal";
import { ConfirmationDialogService } from "@kno2/shared/confirmation-dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";
import { DeliverySummarySendToIntakeComponent } from "../delivery-summary-send-to-intake";

@Component({
    selector: "kno2-release-status-cell-content",
    standalone: true,
    imports: [PopoverDirective, NoOpLink, LowerCasePipe, DatePipe, RouterLink],
    templateUrl: "./release-status-cell-content.component.html",
    styles: `
    .dropdown-menu-absolute {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translate3d(0, 0, 0);
        z-index: 1050; /* Ensure it appears above other elements */
    }
    `
})
export class ReleaseStatusCellContentComponent {
    private readonly messageService = inject(MessagesService);
    private readonly modalService = inject(NgbModal);
    private readonly confirmationDialogService = inject(ConfirmationDialogService);
    private readonly notificationService = inject(NotificationService);
    private readonly httpUtilitiesService = inject(HttpUtilitiesService);
    private readonly fileSaveService = inject(FileSaveService);

    protected event = input.required<UserMessageEvent>();
    protected deliverySummaryEnableDownload = input<boolean>(false);

    public onEventDeleted = output<string>();
    public onDownloadDeliverySummary = output<string>();

    public async downloadDeliverySummary(messageId: string): Promise<void> {
        const { body, headers } = await this.messageService.getDeliverySummary(messageId);
        const fileName = this.httpUtilitiesService.getFileName(headers.get("content-disposition"), "delivery-summary.pdf");
        const fileType = headers.get("content-type");
        await this.fileSaveService.saveFile(body, fileType, fileName);
    }

    public openSendDeliverySummaryToIntakeModal(messageId: string): void {
        const { componentInstance } = this.modalService.open(DeliverySummarySendToIntakeComponent);
        componentInstance.messageId = messageId;
    }

    public async deleteEvent(message: UserMessageEvent): Promise<void> {
        await this.confirmationDialogService
            .open({
                windowClass: "modal-600",
                bodyText: `
                <p>You are about to delete the message or batch with a subject of
                <strong>${message.subject}</strong></p>
                <p>Are you sure?</p>`,
                confirmFn: async () => {
                    await this.messageService.deleteMessage(message.id);
                },
                errFn: (error: Record<"message", string>) => this.notificationService.error(error.message),
                successNotificationText: "Message has been deleted"
            })
            .result.catch(noop);

        this.onEventDeleted.emit(message.id);
    }

    public async showActivity(messageId: string, messagSubject: string): Promise<void> {
        const modal = this.modalService.open(MessageActivityModalComponent, {
            windowClass: "audit-message-modal"
        });

        modal.componentInstance.messageId = messageId;
        modal.componentInstance.messageSubject = messagSubject;

        await modal.result.catch(noop);
    }
}
