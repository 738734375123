<div class="dropdown">
    <button
        style="border: none; background: none; color: #a5b1b6"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i
            class="fa fa-filter"
            [class.active]="isActive()"></i>
    </button>
    <div
        #dropdownMenu
        role="menu"
        aria-labelledby="dropdownMenuButton"
        class="dropdown-menu dropdown-menu-right p-3"
        style="width: 200px">
        <form>
            <div class="form-group d-flex flex-column">
                <label>{{ options().exact ? "Where value equals:" : "Where value contains:" }}</label>
                @switch (options().type) {
                    @case ("text") {
                        <kno2-form-input
                            [control]="form.controls.search"
                            [placeholder]="''" />
                    }
                    @case ("select") {
                        <kno2-form-select
                            [control]="form.controls.search"
                            [options]="selectOptions()" />
                    }
                    @case ("date") {
                        <kno2-form-date-input
                            [control]="form.controls.search"
                            (dateSelect)="applyFilter()" />
                    }
                }
            </div>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-default"
                    (click)="clearFilter()">
                    Clear
                </button>
                <div class="mx-1"></div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    (click)="applyFilter()">
                    Filter
                </button>
            </div>
        </form>
    </div>
</div>
