<kno2-table
    [columns]="columns()"
    [data]="events().items"
    (onFilter)="filterEvents({ prop: $event?.prop, search: $event?.value })">
    <ng-template
        #status
        let-event>
        <kno2-release-status-cell-content
            [event]="event"
            [deliverySummaryEnableDownload]="deliverySummaryEnableDownload"
            (onEventDeleted)="updateEventsQuery()" />
    </ng-template>
    <ng-template
        #subject
        let-event>
        <a
            class="kno2-link"
            [routerLink]="['/release', event.id]">
            {{ event.subject }}
        </a>
    </ng-template>
</kno2-table>

<kno2-pagination
    [collectionSize]="events().totalCount"
    [page]="pageOptions().pageStart"
    [showPageInput]="true"
    [pageSize]="pageOptions()?.pageSize"
    (pageChange)="updateEventsQuery({ pageStart: $event })" />
