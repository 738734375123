import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NoOpLink } from "@kno2/common/directives";
import { InfoPopoverComponent } from "@kno2/shared/info-popover";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";
import { NonPhiFaxComponent } from "./non-phi-fax/non-phi-fax.component";
import { ReleaseDraftsComponent } from "./release-drafts.component";
import { ReleaseHistoryComponent } from "./release-history.component";

@Component({
    selector: "kno2-release",
    standalone: true,
    imports: [NoOpLink, RouterLink, InfoPopoverComponent, ReleaseHistoryComponent, ReleaseDraftsComponent],
    templateUrl: "./release.component.html"
})
export class ReleaseComponent {
    private readonly modalService = inject(NgbModal);
    protected activeTab = "events";

    public async showNonPhiFaxModal(): Promise<void> {
        const modal = this.modalService.open(NonPhiFaxComponent);

        await modal.result.catch(noop);
    }

    public changeActiveTab(tab: "events" | "drafts"): void {
        this.activeTab = tab;
    }
}
