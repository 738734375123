import { inject, Injectable } from "@angular/core";
import { WINDOW_TOKEN } from "@kno2/core/browser";
import { BehaviorSubject, combineLatest, map } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LoadingIndicatorService {
    private readonly window = inject(WINDOW_TOKEN);
    private loadingRoutesSubject = new BehaviorSubject<string[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    /**
     * @deprecated Convert to signal after AngularJS upgrade is complete
     */
    public isLoading$ = combineLatest([this.loadingRoutesSubject, this.loadingSubject]).pipe(map(([routes, loading]) => routes.length > 0 || loading));

    constructor() {
        this.startLoadingListeners();
    }

    public addRoute(route: string): void {
        this.loadingRoutesSubject.next([...this.loadingRoutesSubject.value, route]);
    }

    public removeRoute(route: string): void {
        this.loadingRoutesSubject.next(this.loadingRoutesSubject.value.filter((r) => r !== route));
    }

    /**
     * @deprecated Remove references to window events after AngularJS upgrade is complete
     */
    private startLoadingListeners(): void {
        this.window.addEventListener("loading-started", () => this.loadingSubject.next(true));
        this.window.addEventListener("loading-complete", () => this.loadingSubject.next(false));
    }
}
