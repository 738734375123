import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class FormService {
    public getErrorValues(form: FormGroup): unknown[] {
        const errors: unknown[] = [];

        if (form.errors) {
            errors.push(form.errors);
        }

        Object.keys(form.controls).forEach((key) => {
            const control = form.get(key);

            if (control instanceof FormGroup) {
                errors.push(this.getErrorValues(control));
            } else if (control.errors) {
                errors.push(control.errors);
            }
        });

        return errors.map((error) => Object.values(error)).flat();
    }
}
