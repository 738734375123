import { Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { LoadingIndicatorService } from "./loading-indicator.service";

@Component({
    selector: "kno2-loading-indicator",
    standalone: true,
    imports: [],
    template: `
        @if (isLoading()) {
            <div class="kno2-loading"><img src="/content/images/ajax-loader.gif" /></div>
        }
    `
})
export class LoadingIndicatorComponent {
    private readonly loadingIndicatorService = inject(LoadingIndicatorService);
    protected isLoading = toSignal(this.loadingIndicatorService.isLoading$);
}
