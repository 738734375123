<div class="d-flex justify-content-end">
    @if (pageSize() < collectionSize()) {
        <p-paginator
            [totalRecords]="collectionSize()"
            [rows]="pageSize()"
            [showJumpToPageInput]="showPageInput()"
            (onPageChange)="onPaginationChange($event)">
            <ng-template pTemplate="firstpagelinkicon">
                <i class="fa fa-angle-double-left"></i>
            </ng-template>
            <ng-template pTemplate="previouspagelinkicon">
                <i class="fa fa-angle-left"></i>
            </ng-template>
            <ng-template pTemplate="nextpagelinkicon">
                <i class="fa fa-angle-right"></i>
            </ng-template>
            <ng-template pTemplate="lastpagelinkicon">
                <i class="fa fa-angle-double-right"></i>
            </ng-template>
        </p-paginator>
    }
</div>
