import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class HttpUtilitiesService {
    public getFileName(contentDisposition: string, defaultName: string): string {
        if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, "");
            }
        }

        return defaultName;
    }
}
