import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FaxPipe } from "@kno2/common/pipes";
import { NotificationService } from "@kno2/common/services/notifications";
import { requiredWithMessageValidator } from "@kno2/common/validators";
import { SessionService } from "@kno2/core/session";
import { DocumentSourcesService } from "@kno2/data-access/document-sources";
import { MessagesService } from "@kno2/data-access/messages";
import { Message } from "@kno2/data-access/messages/message.models";
import { FormInputComponent, FormSelectComponent, FormService } from "@kno2/shared/forms";
import { NotificationAlertComponent } from "@kno2/shared/notification-alert";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "kno2-non-phi-fax",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormInputComponent, FormSelectComponent, NotificationAlertComponent],
    providers: [FaxPipe],
    templateUrl: "./non-phi-fax.component.html",
    styles: ``
})
export class NonPhiFaxComponent implements OnInit {
    private readonly documentSourcesService = inject(DocumentSourcesService);
    private readonly messagesService = inject(MessagesService);
    private readonly sessionService = inject(SessionService);
    private readonly activeModal = inject(NgbActiveModal);
    private readonly formService = inject(FormService);
    private readonly notificationService = inject(NotificationService);
    private readonly faxPipe = inject(FaxPipe);
    private draftId: string;

    protected isSubmitting = false;
    protected isUploading = false;
    protected error: string;
    protected errorMessages: string[] = [];
    protected faxNumberOptions: [string, string][] = [];
    protected form = new FormGroup({
        subject: new FormControl("", requiredWithMessageValidator('"Subject" is required.')),
        toAddress: new FormControl("", requiredWithMessageValidator('"To" fax number is required.')),
        fromAddress: new FormControl(null, requiredWithMessageValidator('"From" fax number is required.')),
        attachments: new FormControl([], requiredWithMessageValidator("Missing file attachment.")),
        body: new FormControl("")
    });

    public async ngOnInit(): Promise<void> {
        const addresses = await this.documentSourcesService.getDocumentSourcesByUser(this.sessionService.getProfile().userId);
        this.faxNumberOptions = addresses.filter(({ type }) => type === "Fax").map(({ address }) => [this.faxPipe.transform(address), address]);
    }

    public cancel(): void {
        this.messagesService.deleteMessage(this.draftId);
        this.activeModal.dismiss();
    }

    public async onFilesSelected(event: Event): Promise<void> {
        const target = event.target as HTMLInputElement;
        const files: FileList = target.files;

        if (!files.length) return;

        this.isUploading = true;
        if (!this.draftId) await this.createDraft();

        for (let i = 0; i < target.files.length; i++) {
            await this.addAttachment(target.files.item(i));
        }
        this.isUploading = false;
    }

    private async createDraft(): Promise<void> {
        const { attachments, ...message }: Partial<Message> = this.form.value;
        const draft = await this.messagesService.saveDraft(message);

        this.draftId = draft.id;
    }

    private async addAttachment(file: File): Promise<void> {
        try {
            if (file.size === 0) throw `${file.name} is empty. Please upload something more substantial.`;
            if (file.size > 20971520) throw `${file.name} exceeds the maximum (20MB) allowed size.`;

            const result = await this.messagesService.uploadAttachment(this.draftId, file);
            this.form.controls.attachments.setValue([...this.form.value.attachments, result]);
        } catch (err) {
            this.error = "Unable to attach document.";
            if (err.error?.message) this.errorMessages = [`${file.name}: ${err.error.message}`];
        }
    }

    public async send(): Promise<void> {
        const errors = this.formService.getErrorValues(this.form);
        if (errors.length) {
            this.error = "Please fix the following errors before sending.";
            this.errorMessages = errors as string[];
            return;
        }
        this.isSubmitting = true;

        try {
            const { attachments, ...message }: Partial<Message> = this.form.value;
            await this.messagesService.sendMessage(this.draftId, message);
            this.notificationService.success("The message was sent successfully.");
            this.activeModal.close();
        } catch (err) {
            this.error = "There was an error sending your message. Please contact support.";
            if (err.message) this.error = err.message;
        } finally {
            this.isSubmitting = false;
        }
    }
}
