@let tooltip = "View all activity surrounding this message by selecting the activity below and the date range in which to view the activity that has occurred.  The results will appear on the right hand side.";

<div class="audit-modal">
    <div class="modal-header">
        <button
            type="button"
            class="close"
            aria-hidden="true"
            (click)="close()">
            &times;
        </button>
        <div class="row">
            <div class="col-sm-4">
                <h2 class="modal-title">
                    Message Activity
                    <kno2-info-popover
                        [content]="tooltip" />
                </h2>
            </div>
        </div>
    </div>

    <div class="row row-eq-height">
        <div class="col-sm-3">
            <div class="inbox">
                <div class="col-sm-12 inbox-menu">
                    <ul>
                        <li
                            class="title alerts-title"
                            style="cursor: default">
                            Activity Types
                        </li>
                        @for (filter of filters; track $index) {
                            <li
                                [class.alerts-menu-active]="filterKey === filter.key"
                                (click)="updateQuery(filter.key)">
                                {{ filter.display }}
                            </li>
                        }
                    </ul>
                    <div class="form-group audit-dates-filter-container pt-2">
                        <label>Start Date</label>
                        <kno2-form-date-input
                            placeholder="Start Date"
                            [control]="dateRangeForm.get('startDate')"
                            [config]="{ maxDate: dateRangeForm.get('endDate').value }"
                            (dateSelect)="updateQuery(filterKey)" />
                        <div class="mt-3"></div>
                        <label>End Date</label>
                        <kno2-form-date-input
                            placeholder="End Date"
                            [control]="dateRangeForm.get('endDate')"
                            [config]="{ minDate: dateRangeForm.get('startDate').value }"
                            (dateSelect)="updateQuery(filterKey)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="table-wrapper">
                <div class="row">
                    <div
                        class="col-sm-12"
                        style="cursor: pointer; margin-top: 10px; margin-bottom: 10px">
                        <span message="message">
                            {{ messageSubject }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        @if (!pagedAuditEntires()?.[pageOptions.page - 1]?.length) {
                            <p ng-show="$ctrl.entriesLoaded && $ctrl.auditEntries.length == 0">There are no results matching your criteria.</p>
                        } @else {
                            <kno2-table
                                [data]="pagedAuditEntires()[pageOptions.page - 1]"
                                [columns]="columns()" />

                            <kno2-pagination
                                [collectionSize]="auditEntries().length"
                                [page]="pageOptions.page"
                                [pageSize]="pageOptions.pageSize"
                                (pageChange)="pageOptions = { page: $event, pageSize: 10 }" />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
