<div
    (click)="toggleCollapse.emit()"
    class="inline-box-header rules-header">
    <div class="inline-box-title rules-header-title">
        <h2>Knowledge Base</h2>
    </div>
    <span
        class="pull-left"
        [kno2Popover]="tooltip">
        <i class="fa fa-info-circle box-info"></i>
    </span>
    <div class="header-ctrls">
        <div class="header-ctrl box-icon">
            <a>
                <i
                    class="fa"
                    [class.fa-chevron-down]="!expanded"
                    [class.fa-chevron-up]="expanded"></i>
            </a>
        </div>
    </div>
</div>
<div
    class="inline-box-content"
    [class.in]="expanded"
    [class.collapse]="!expanded">
    <p>Click the link below to access the {{ brandDisplayName }} Knowledge Base:</p>
    <a
        [href]="knowledgeBaseUrl"
        class="qt-link"
        target="_blank">
        {{ brandDisplayName }} Knowledge Base
    </a>
</div>
