import { NgTemplateOutlet, TitleCasePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, output, TemplateRef } from "@angular/core";
import { SplitOnPipe } from "@kno2/common/pipes";
import { TableModule } from "primeng/table";
import { ColumnFilterComponent, ColumnFilterOptions } from "./column-filter/column-filter.component";

export type TableColumn = {
    field: string;
    headerText?: string;
    cellClass?: string;
    canSort?: boolean;
    filter?: ColumnFilterOptions;
    sortDir?: "asc" | "desc";
    templateRef?: TemplateRef<unknown>;
    formatter?: (value: unknown) => string;
};

export type TableSortEvent = {
    field: string;
    sortDir: "asc" | "desc";
};

@Component({
    selector: "kno2-table",
    standalone: true,
    imports: [TableModule, ColumnFilterComponent, NgTemplateOutlet, SplitOnPipe, TitleCasePipe],
    templateUrl: "./table.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        ::ng-deep .p-datatable-wrapper {
            overflow: visible !important;
        }
    `
})
export class TableComponent {
    public columns = input.required<TableColumn[]>();
    public data = input.required<unknown[]>();
    public rowActions = input<TemplateRef<unknown> | null>();
    public onSort = output<TableSortEvent>();
    public onFilter = output<{ prop: string; value: string }>();
    public activeFilter = "";

    public sort(field: string, options: TableColumn): void {
        options.sortDir = options.sortDir === "asc" ? "desc" : "asc";

        this.onSort.emit({ field, sortDir: options.sortDir });
    }

    public filter(field: string, event?: { prop: string; value: string }): void {
        if (!event) this.onFilter.emit(null);
        const { prop, value } = event;
        this.activeFilter = !event ? null : field;
        this.onFilter.emit({
            prop: prop || field,
            value
        });
    }
}
