import { AfterViewInit, Component, inject, OnDestroy, OnInit, signal, TemplateRef, viewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { MessagesQuery, MessagesService } from "@kno2/data-access/messages";
import { ConfirmationDialogService } from "@kno2/shared/confirmation-dialog";
import { PaginationComponent } from "@kno2/shared/pagination";
import { TableColumn, TableComponent } from "@kno2/shared/table";
import { noop } from "angular";
import { DateTime } from "luxon";

@Component({
    selector: "kno2-release-drafts",
    standalone: true,
    imports: [TableComponent, PaginationComponent, RouterLink],
    templateUrl: "./release-drafts.component.html",
    styles: ``
})
export class ReleaseDraftsComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly messagesService = inject(MessagesService);
    private readonly router = inject(Router);
    private readonly confirmationDialogService = inject(ConfirmationDialogService);
    private refreshEventsInterval: ReturnType<typeof setInterval>;

    protected subjectTemplate = viewChild<TemplateRef<{ message: unknown }>>("subject");

    protected pageOptions = signal<MessagesQuery>({
        pageStart: 1,
        pageSize: 10,
        isDraft: true
    });
    protected messages = this.messagesService.getMessages(this.pageOptions);
    protected columns: TableColumn[];

    public ngOnInit(): void {
        this.setRefreshEventsInterval();
    }

    public ngAfterViewInit(): void {
        this.columns = this.getColumns();
    }

    public ngOnDestroy(): void {
        clearInterval(this.refreshEventsInterval);
    }

    public updateMessagesQuery(query?: MessagesQuery): void {
        this.pageOptions.update((options) => ({
            ...options,
            ...(query || {})
        }));
        this.setRefreshEventsInterval();
    }

    public editDraft(id: string): void {
        this.router.navigate(["release", "draft", id]);
    }

    public async deleteDraft(id: string): Promise<void> {
        await this.confirmationDialogService
            .open({
                windowClass: "modal-600",
                headerText: "Delete Draft",
                bodyText: `
                <p>You are about to delete this draft.</p>
                <p>Are you sure?</p>`,
                confirmFn: async () => {
                    await this.messagesService.deleteMessage(id);
                },
                successNotificationText: "The draft was deleted successfully."
            })
            .result.catch(noop);
        this.updateMessagesQuery();
    }

    private getColumns(): TableColumn[] {
        return [
            {
                field: "createdDate",
                headerText: "Sent",
                formatter: (value: string) => DateTime.fromISO(value, { zone: "utc" }).setZone("local").toLocaleString(DateTime.DATE_MED)
            },
            {
                field: "origin",
                headerText: "Source"
            },
            {
                field: "subject",
                templateRef: this.subjectTemplate()
            },
            {
                field: "toAddress",
                headerText: "To",
                formatter: (value: string) => value || "N/A"
            },
            {
                field: "fromAddress",
                headerText: "From",
                formatter: (value: string) => value || "N/A"
            }
        ];
    }

    private setRefreshEventsInterval(): void {
        clearInterval(this.refreshEventsInterval);
        this.refreshEventsInterval = setInterval(() => {
            this.updateMessagesQuery();
        }, 15000);
    }
}
