import { Component, computed, effect, ElementRef, inject, input, output, Renderer2, viewChild } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormDateInputComponent, FormInputComponent, FormSelectComponent } from "@kno2/shared/forms";
import { DateTime } from "luxon";

export interface ColumnFilterOptions {
    type: "text" | "select" | "date";
    prop?: string;
    exact?: boolean;
    values?: string[];
}

@Component({
    selector: "kno2-column-filter",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormSelectComponent, FormInputComponent, FormDateInputComponent],
    templateUrl: "./column-filter.component.html",
    styles: `
        .dropdown-menu {
            width: 200px;
        }
        .active {
            color: #333;
        }
    `
})
export class ColumnFilterComponent {
    private renderer = inject(Renderer2);
    private dropdownMenu = viewChild<ElementRef>("dropdownMenu");

    protected form = new FormGroup({
        search: new FormControl("", {
            updateOn: "change"
        })
    });

    public isActive = input<boolean>(false);
    public options = input<ColumnFilterOptions>();

    public selectOptions = computed(() => (this.options().values?.length ? this.options().values.map((value) => [value, value]) : []));
    public onFilter = output<{ prop: string; value: string }>();

    public constructor() {
        effect(() => {
            if (!this.isActive()) this.form.reset();
        });
    }

    public clearFilter(): void {
        this.onFilter.emit(null);
        this.form.reset();
        this.renderer.removeClass(this.dropdownMenu().nativeElement, "show");
    }

    public applyFilter(): void {
        let search = this.form.value.search;
        if (this.options().type === "date") {
            search = DateTime.fromObject(search, { zone: "local" }).toISO();
        }

        this.onFilter.emit({
            prop: this.options().prop,
            value: search
        });
        this.renderer.removeClass(this.dropdownMenu().nativeElement, "show");
    }
}
