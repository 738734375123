import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";

export interface DocumentSource {
    id: string;
    address: string;
    type: string;
}

@Injectable({
    providedIn: "root"
})
export class DocumentSourcesService {
    private readonly httpClient = inject(HttpClient);

    public getDocumentSourcesByTenantAndUserRole = (userId: string): Promise<DocumentSource[]> =>
        firstValueFrom(this.getDocumentSourcesByTenantAndUserRole$(userId));
    public getDocumentSourcesByUser = (userId: string): Promise<DocumentSource[]> => firstValueFrom(this.getDocumentSourcesByUser$(userId));

    private getDocumentSourcesByTenantAndUserRole$(userId: string): Observable<DocumentSource[]> {
        return this.httpClient.get<DocumentSource[]>(`/api/users/${userId}/releasetypedocumentsources`);
    }

    private getDocumentSourcesByUser$(userId: string): Observable<DocumentSource[]> {
        return this.httpClient.get<DocumentSource[]>(`/api/users/${userId}/documentsources`);
    }
}
