<form
    [formGroup]="form"
    (ngSubmit)="send()">
    <div class="modal-header">
        <button
            type="button"
            class="close"
            (click)="cancel()">
            &times;
        </button>
        <h2 class="modal-title">Send Non-PHI Fax</h2>
    </div>
    <div class="modal-body">
        <div class="message-body">
            @if (error) {
                <kno2-notification-alert
                    type="danger"
                    [title]="error"
                    [messages]="errorMessages"
                    (dismiss)="error = null"></kno2-notification-alert>
            }
            <div class="row">
                <div class="col-sm-12">
                    <div class="intake-message-label col-sm-2 mt-2">Subject:</div>
                    <div class="col-sm-10">
                        <kno2-form-input
                            name="subject"
                            placeholder="Add fax subject"
                            [control]="form.get('subject')" />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-12">
                    <div class="intake-message-label col-sm-2 mt-2">To:</div>
                    <div class="col-sm-10">
                        <kno2-form-input
                            name="toAddress"
                            mask="(000)000-0000"
                            [control]="form.get('toAddress')" />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-12">
                    <div class="intake-message-label col-sm-2 mt-2">From:</div>
                    <div class="col-sm-10">
                        <kno2-form-select
                            name="fromAddress"
                            placeholder="Select a fax number"
                            [control]="form.get('fromAddress')"
                            [isDefaultOptionEnabled]="false"
                            [options]="faxNumberOptions" />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-12 message-body-container">
                    <div class="intake-message-label col-sm-2 mt-2">Message:</div>
                    <div class="col-sm-10">
                        <textarea
                            [formControl]="form.get('body')"
                            class="form-control"
                            rows="10"
                            placeholder="Fax message body."
                            style="resize: none"></textarea>
                    </div>
                </div>
            </div>
            <br />
            <div
                class="form-group row"
                style="padding-left: 15px">
                <div
                    class="col-sm-2 intake-message-label"
                    style="top: 2px">
                    Attachments:
                </div>
                <div class="col-sm-4">
                    @for (attachment of form.get("attachments").value; track $index) {
                        <div>
                            <span class="intake-message-label">{{ attachment.fileName }}</span>
                        </div>
                    }
                </div>
                <div class="col-sm-6">
                    <span
                        class="btn btn-inverse fileinput-button pull-right"
                        style="top: -5px">
                        <input
                            [disabled]="isUploading || isSubmitting"
                            (change)="onFilesSelected($event)"
                            id="selectedFile"
                            type="file"
                            name="file"
                            multiple />
                        @if (isUploading) {
                            <span class="spinner-border spinner-border-sm"></span>
                        }
                        Browse
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            id="cancelFax"
            type="button"
            (click)="cancel()"
            class="btn"
            data-style="zoom-in">
            Cancel
        </button>
        <button
            id="submitFax"
            type="submit"
            [disabled]="isSubmitting || isUploading"
            class="btn btn-primary"
            data-style="zoom-in">
            @if (isSubmitting) {
                <span class="spinner-border spinner-border-sm"></span>
            }
            Send
        </button>
    </div>
</form>
