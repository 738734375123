angular.module("kno2.release").service("ReleaseService", ReleaseService);

ReleaseService.$inject = ["$http", "$location", "SureScriptsService", "CacheService", "DirectoryService", "SessionService"];
function ReleaseService($http, $location, sureScriptsService, cacheService, directoryService, sessionService) {
    this.currentDraft = {};

    this.getCurrentDraft = function () {
        return this.currentDraft;
    };

    this.setCurrentDraft = function (draft) {
        this.currentDraft = draft;
    };

    this.deleteDraft = function (draftId) {
        cacheService.clear("/messages/draft");
        return $http.delete("/api/messages/" + draftId);
    };

    this.getFromOptions = function (userId) {
        return $http.get("/api/messages?userId=" + userId).then(function (response) {
            return response.data;
        });
    };

    this.validateToAddresses = directoryService.validateAddresses;

    this.sendMessage = function (id, message) {
        var userId = sessionService.getProfile().userId;
        // Clear cache to reload Releases and Drafts
        cacheService.clear("/users/{id}/events".replace("{id}", userId), "/messages/draft");
        return $http.post("/api/messages/{id}/send".replace("{id}", id), message);
    };

    this.sendNonPhiFax = function () {
        var userId = sessionService.getProfile().userId;
    };

    this.forwardMessage = function (id, message) {
        message.id = id;
        return $http.post("/api/messages/{id}/forward".replace("{id}", id), message);
    };

    this.replyMessage = function (id, message) {
        message.id = id;
        return $http.post("/api/messages/{id}/reply".replace("{id}", id), message);
    };

    /**
     * @deprecated Use MessageService.saveDraft
     */
    this.saveDraft = function (draftId, message, attachments, patient) {
        var msg = message;
        if (message.originalObjectId != undefined) {
            msg.originalObjectId = message.originalObjectId;
        } else {
            msg.originalObjectId = message.id;
        }

        msg.toAddress = _.map(message.toAddresses, "text").join(";");
        msg.attachments = attachments;
        msg.patient = patient;
        msg.id = draftId;

        msg.subject = message.subject || message.releaseType;

        var url = "/api/messages";

        if (message.id) {
            url += "/" + message.id;
        }

        var promise = $http.put(url, msg).then(function (res) {
            cacheService.clear("/release/draft");
            return res.data;
        });
        return promise;
    };

    this.saveDraftWithAttachments = function (draftId, message, includeAttachments) {
        var promise = $http.post("/api/messages/" + message.id + "/copy").then(function (res) {
            cacheService.clear("/release/draft");
            return res.data;
        });
        return promise;
    };

    this.getFromAddresses = function () {
        return sureScriptsService.getAddresses();
    };
}
