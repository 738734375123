import { Component, model, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { debounceTime, Subject } from "rxjs";

@Component({
    selector: "kno2-pagination-search",
    standalone: true,
    imports: [FormsModule],
    template: `
        <div
            class="dataTables_filter"
            id="DataTables_Table_0_filter">
            <div class="input-group pull-right settings-search">
                <input
                    class="form-control-auto pull-right"
                    type="text"
                    [(ngModel)]="search"
                    (ngModelChange)="onSearchChange()"
                    placeholder="Search"
                    aria-controls="DataTables_Table_0" />
                <div class="input-group-append">
                    <span
                        class="input-group-text"
                        id="basic-addon1">
                        <i class="fa fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
    `
})
export class PaginationSearchComponent {
    protected search = model<string>();
    protected searchChange = output<{ search: string }>();

    private search$ = new Subject<string>();

    constructor() {
        this.search$.pipe(debounceTime(300), takeUntilDestroyed()).subscribe((search) => {
            this.searchChange.emit({ search });
        });
    }

    protected onSearchChange(): void {
        this.search$.next(this.search());
    }
}
