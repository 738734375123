import { HttpClient, HttpResponse } from "@angular/common/http";
import { inject, Injectable, Signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { filterNonEmptyProperties } from "@kno2/common/utils";
import { Message } from "primeng/api";
import { firstValueFrom, Observable, switchMap } from "rxjs";
import { Classification, MessageAttachment } from "./message.models";

export interface MessagesQuery {
    pageSize: number;
    pageStart: number;
    isDraft?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class MessagesService {
    private readonly httpClient = inject(HttpClient);

    public getMessages = (pageOptions: Signal<MessagesQuery>): Signal<{ items: Message[]; totalCount: number }> =>
        toSignal(this.getMessages$(pageOptions), { initialValue: { items: [], totalCount: 0 } });
    public getDeliverySummary = (id: string): Promise<HttpResponse<ArrayBuffer>> => firstValueFrom(this.getDeliverySummary$(id));
    public deliverySummarySendToIntake = (id: string): Promise<void> => firstValueFrom(this.deliverySummarySendToIntake$(id));
    public getClassifications = (): Promise<Classification[]> => firstValueFrom(this.getClassifications$());
    public saveDraft = (message: Partial<Message>): Promise<Message> => firstValueFrom(this.saveDraft$(message));
    public sendMessage = (id: string, message: Partial<Message>): Promise<Message> => firstValueFrom(this.sendMessage$(id, message));
    public uploadAttachment = (messageId: string, file: File): Promise<MessageAttachment> => firstValueFrom(this.uploadAttachment$(messageId, file));
    public deleteMessage = (id: string): Promise<void> => firstValueFrom(this.deleteMessage$(id));

    private getMessages$(query: Signal<MessagesQuery>): Observable<{ items: Message[]; totalCount: number }> {
        return toObservable(query).pipe(
            switchMap((params) =>
                this.httpClient.get<{ items: Message[]; totalCount: number }>(`/api/messages`, {
                    params: {
                        pageSize: 30,
                        pageStart: 1,
                        ...filterNonEmptyProperties(params)
                    }
                })
            )
        );
    }

    private getDeliverySummary$(id: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.httpClient.get(`/api/messages/${id}/deliverysummary`, {
            responseType: "arraybuffer",
            headers: {
                Accept: "application/pdf"
            },
            observe: "response"
        });
    }

    private deliverySummarySendToIntake$ = (id: string): Observable<void> =>
        this.httpClient.post<void>(`/api/messages/${id}/deliverysummary/sendtointake`, null);

    private getClassifications$ = (): Observable<Classification[]> => this.httpClient.get<Classification[]>(`/api/messages/classifications`);

    private saveDraft$(message: Partial<Message>): Observable<Message> {
        let url = `/api/messages`;

        if (message.id) url += `/${message.id}`;
        return this.httpClient.put<Message>(url, message);
    }

    private sendMessage$(id: string, message: Partial<Message>): Observable<Message> {
        return this.httpClient.post<Message>(`/api/messages/${id}/send`, message);
    }

    private uploadAttachment$(messageId: string, file: File): Observable<MessageAttachment> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachmentMeta", JSON.stringify({ convert: true, usePriorityQueue: true }));

        return this.httpClient.post<MessageAttachment>(`/api/messages/${messageId}/attachments`, formData);
    }

    private deleteMessage$(id: string): Observable<void> {
        return this.httpClient.delete<void>(`/api/messages/${id}`);
    }
}
