<form [formGroup]="preferencesForm()">
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-1">
            <div class="form-check">
                <input
                    name="enabled2FA"
                    formControlName="emailSystemNotifications"
                    class="form-check-input mr-2"
                    type="checkbox" />
                <label class="form-check-label">
                    Notifications
                    <span
                        class="ml-2"
                        [kno2Popover]="emailNotificationsTooltip">
                        <i class="fa fa-info-circle box-info"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-1">
            <div class="form-check">
                <input
                    name="enabled2FA"
                    formControlName="emailNewMessages"
                    class="form-check-input mr-2"
                    type="checkbox" />
                <label class="form-check-label">
                    New messages received
                    <span
                        class="ml-2"
                        [kno2Popover]="emailNewMessagesTooltip">
                        <i class="fa fa-info-circle box-info"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
    @if (isAdministrator()) {
        <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-1">
                <div class="form-check">
                    <input
                        name="enabled2FA"
                        formControlName="emailUnassignedMessages"
                        class="form-check-input mr-2"
                        type="checkbox" />
                    <label class="form-check-label">
                        Unassigned messages (Administrators only)
                        <span
                            class="ml-2"
                            [kno2Popover]="emailUnassignedMessagesTooltip">
                            <i class="fa fa-info-circle box-info"></i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-1">
                <div class="form-check">
                    <input
                        name="enabled2FA"
                        formControlName="emailUnprocessedMessages"
                        class="form-check-input mr-2"
                        type="checkbox" />
                    <label class="form-check-label">
                        Unprocessed messages (Administrators only)
                        <span
                            class="ml-2"
                            [kno2Popover]="emailUnprocessedMessagesTooltip">
                            <i class="fa fa-info-circle box-info"></i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-1">
                <div class="form-check">
                    <input
                        name="enabled2FA"
                        formControlName="emailFailedMessagesDigest"
                        class="form-check-input mr-2"
                        type="checkbox" />
                    <label class="form-check-label">
                        Failed messages digest (Administrators only)
                        <span
                            class="ml-2"
                            [kno2Popover]="emailFailedMessagesTooltip">
                            <i class="fa fa-info-circle box-info"></i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    }

    <div class="form-group row">
        <span class="col-sm-8 col-sm-offset-1">Notice: Email Notification Settings could take up to 36 hours to fully propagate through our system</span>
    </div>
</form>
