import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";

export function requiredWithMessageValidator(message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const errors = Validators.required(control) ? { required: message } : null;
        return errors;
    };
}

export function maxLengthWithMessageValidator(maxLength: number, message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const errors = Validators.maxLength(maxLength)(control) ? { maxLength: message } : null;
        return errors;
    };
}

export function minLengthWithMessageValidator(maxLength: number, message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const errors = Validators.minLength(maxLength)(control) ? { maxLength: message } : null;
        return errors;
    };
}

export function addressConsecutivePeriodValidator(message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const errors = control.value?.length > 0 && control.value.indexOf("..") !== -1 ? { addressConsectivePeriod: message } : null;
        return errors;
    };
}

export function addressStartEndPeriodValidator(message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const errors =
            control.value?.length > 0 && control.value.indexOf(".") === 0 && control.value.lastIndexOf(".") === control.value.length - 1
                ? { addressStartEndPeriod: message }
                : null;
        return errors;
    };
}

export function addressPatternValidator(allowedSpecialChars: string, message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        const pattern = "^[0-9a-zA-Z" + allowedSpecialChars + "\\.!#$_%&/`'|{}=?*+^~-]+$";
        const regEx = new RegExp(pattern);
        return control?.value.length > 0 && !regEx.test(control.value) ? { addressPattern: message } : null;
    };
}

export function addressPhoneValidator(message: string): ValidatorFn {
    return (control: AbstractControl): Record<string, boolean | string> | null => {
        if (control?.value.indexOf("@") >= 0) {
            return null;
        }
        const pattern = /^(?:\+{0,1})?(\d{1})?(?:[-.\s]?)(?:\(?(\d{3})\)?)?(?:[-.\s]?)(\d{3})(?:[-.\s]?)(\d{4})$/;
        const regEx = new RegExp(pattern);
        return control?.value.length > 0 && !regEx.test(control.value) ? { addressPhone: message } : null;
    };
}
